import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BnNgIdleService } from 'bn-ng-idle';
import { KeycloakService } from 'keycloak-angular';
import { Subscription, auditTime, debounceTime, fromEvent, interval, merge } from 'rxjs';
import { jwtDecode } from 'jwt-decode';
import { AppAuthGuard } from './utility/app.guard';
import { ApplicationService } from 'src/services/application.service';
import { MainHeaderService } from './components/main-header/main-header.service';
import { NavigationEnd } from '@angular/router';
import { filter } from 'rxjs';
import { RolesService } from 'src/services/roles.service';
import { IndexDBService } from 'src/services/indexdb.service';
import initHelpHero from 'helphero';
import { SettingsService } from 'src/services/settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  status = 'ONLINE';
  isConnected = true;
  simpleItems = [
    { 'value': 1, 'label': 'Vilnius' },
    { 'value': 2, 'label': '22-Vilnius' },
    { 'value': 3, 'label': '33-Vilnius' }

  ];
  selectsimpleItems: any;
  showContent = true;
  RefreshTokenInterval: number = 270;
  refreshlogin;
  isCompactMenu;
  private helpHero: any;
  isTabActive = true;

  private _subscriptions: Subscription[] = [];

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private mainHeaderService: MainHeaderService,
    private bnIdle: BnNgIdleService,
    private keycloak: KeycloakService,
    private appSvc: ApplicationService,
    public authGuard: AppAuthGuard,
    private rolesService: RolesService,
    private indexDBService: IndexDBService,
    private settingsService: SettingsService) {

    if (!window.location.href.includes("/offline")) {
      this.refreshlogin = this.keycloak.getKeycloakInstance();
      if (this.refreshlogin && this.refreshlogin?.refreshToken ) {
        const tokenInfo1 = this.getDecodedAccessToken(this.refreshlogin.refreshToken);
        const timeLocal = new Date().getTime();
        const timeSkew = Math.floor(timeLocal / 1000) - tokenInfo1.iat;
        const expiresIn = (tokenInfo1['exp'] - (new Date().getTime() / 1000) + timeSkew) * 1000;
        const sessionIdle = Math.round(expiresIn / 1000) - 30;
        sessionStorage.setItem('sessionIdle', sessionIdle.toString());

        this._subscriptions.push(merge(
          fromEvent(document, 'mousemove'),
          fromEvent(document, 'click'),
          fromEvent(document, 'mousedown'),
          fromEvent(document, 'keypress'),
          fromEvent(document, 'DOMMouseScroll'),
          fromEvent(document, 'mousewheel'),
          fromEvent(document, 'touchmove'),
          fromEvent(document, 'MSPointerMove'),
          fromEvent(window, 'mousemove'),
          fromEvent(window, 'resize'),
        ).pipe(auditTime(5000)).subscribe(() => {
          localStorage.setItem('lastUserAction', new Date().toString());
        }));

        this._subscriptions.push(this.bnIdle.startWatching(15).subscribe((res) => {
          if (res) {
            const lastUserActivity = localStorage.getItem('lastUserAction');
            if (!lastUserActivity) { return localStorage.setItem('lastUserAction', new Date().toString()); }

            const timeIdle = (new Date().getTime() - new Date(lastUserActivity).getTime()) / 1000;

            if (timeIdle > parseInt(sessionStorage.getItem('sessionIdle'))) {
              console.log('LOGGING OUT BECUAE OF SESSION IDLE');
              sessionStorage.clear();
              this.keycloak.logout(window.location.origin + '/login').then(() => this.keycloak.clearToken());
            }
          }
        }));

        this.keycloak.getToken().then(token => {
          sessionStorage.setItem('AccessToken', 'Bearer ' + token);

          this.getTokenExpireTime(token);
        });
      } else {
        sessionStorage.clear();
      }
    }
  }
  getDecodedAccessToken(token: string): any {
    try {
      return jwtDecode(token);
    } catch (Error) {
      return null;
    }
  }
  loginRefresh() {
    this.keycloak.updateToken(30).then(a => {
      if (!a) {
        this.loginRefresh();
      } else {
        this.keycloak.getToken().then(token => {
          sessionStorage.setItem('AccessToken', 'Bearer ' + token);
        });
      }
    });
  }

  getTokenExpireTime(token) {
    const tokenInfo = this.getDecodedAccessToken(token);
    var timeLocal = new Date().getTime();
    let timeSkew = Math.floor(timeLocal / 1000) - tokenInfo.iat;
    var expiresIn = (tokenInfo['exp'] - (new Date().getTime() / 1000) + timeSkew) * 1000;
    this.RefreshTokenInterval = Math.round(expiresIn / 1000) - 30;
    interval(this.RefreshTokenInterval * 1000).subscribe((func => {
      this.loginRefresh();
    }));
  }

  ngOnInit() {
    if (this.settingsService.settings.helpHeroKey) {
      this.helpHero = initHelpHero(this.settingsService.settings.helpHeroKey); // Initialize HelpHero with key
    }
    // Identify user if session exists
    const userId = sessionStorage.getItem('UserId');
    const userName = sessionStorage.getItem('UserName');
    const roles = JSON.parse(sessionStorage.getItem('UserRoles') || '[]');
    if (userId && userName && roles.length > 0) {
      this.identifyUser(userId, userName, roles);
    }
    // if we are on the landing page for a project (just project name in url),
    // then we invoke an auto-redirect to home page using the header roles refresh flow
    this.router.events.pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
      .subscribe(() => {
        const params = this.route.firstChild?.snapshot.params;
        if (params && params.projectName && this.router.url === '/' + params.projectName) {
          this.appSvc.loginUserKeycloak().subscribe(res => {
            const roles = res.roles;
            this.identifyUser(res.userId, res.userfullName, res.roles);
            sessionStorage.setItem('UserId', res.userId);
            this.mainHeaderService.onRolesUpdated();
            const projectRoles = [this.rolesService.PROJECT_MANAGER, this.rolesService.PROJECT_SUPPORT,
               this.rolesService.PROJECT_MANAGER_C,
               this.rolesService.EXECUTION_TEAM_LEAD, this.rolesService.EXECUTION_OWNER, this.rolesService.POLICY_SME,
               this.rolesService.MAINTENANCE_MANAGER, this.rolesService.OPERATION_SME, this.rolesService.REMOTE_OPERATOR]
            if (projectRoles.includes(roles[0])) {
              this.router.navigateByUrl(`/${params.projectName}/homepage`);
            } else {
              this.router.navigateByUrl('/operationscenter');
            }
          });
        }
      });

      //Added for the persistent data
      if (navigator.storage && navigator.storage.persist) {
        navigator.storage.persist().then((isPersisted) => console.log(`Persisted storage granted: ${isPersisted}`))
      }
  }

  identifyUser(userId: string, userName: string, roles: string[]): void {
    if (this.helpHero) {
      this.helpHero.identify(userId, { 
        name: userName,
        roles: roles
      });
    }
  }

  getCompactMenuValue(value) {
    this.isCompactMenu = value;
  }

  ngOnDestroy() {
    this._subscriptions.forEach(sub => sub.unsubscribe());
  }

}
